import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import parse from "html-react-parser";
import breakpoints from "../../utils/breakpoints";
import { H4, Metadata } from "../typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CTA from "../cta";

const Container = styled.div`
  background-color: var(--brand-off-white);
  padding: 5.4rem 0;

  @media ${breakpoints.tabletSm} {
    padding: 6.6rem 0 8rem 0;
  }
  @media ${breakpoints.tablet} {
    padding: 14.5rem 0 12rem 0;
  }
`;

const SectionText = styled.div`
  position: relative;
  text-align: left;
  padding: 2.4rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  letter-spacing:0;


  @media ${breakpoints.tabletSm} {
    width:50%;
    padding: 4rem calc(var(--grid-padding));
  }

  @media ${breakpoints.tablet} {
    padding: 4rem calc(var(--grid-padding)* 1.5);
  }

  @media ${breakpoints.desktop} {
    padding: 4rem calc(var(--grid-padding)) 4rem calc(var(--grid-padding) * 1.8);
  }
`;

const SectionHeadingWrapper = styled.div`
  position: relative;
  padding-bottom: 0.8rem;
  text-align: left;

  @media ${breakpoints.tabletSm} {
    position: relative;
    padding-bottom: 1.6rem;
  }
  @media ${breakpoints.tablet} {
    padding-bottom: 1.5rem;
  }
`;

const SectionHeading = styled(H4)`
  margin: 0 auto 0 0;
  font-size: 3.2rem;
  line-height: 3.2rem;

  @media ${breakpoints.tabletSm} {
    font-size: 4rem;
    line-height: 4.0rem;
  }

  @media ${breakpoints.tablet} {
    font-size: 4.8rem;
    line-height: 5.0rem;
  }

  @media ${breakpoints.desktop} {
    font-size: 6.4rem;
    line-height: 6.0rem;
  }

  br:first-of-type {
    display: none;

    @media ${breakpoints.tablet} {
      display: block;
    }

    @media ${breakpoints.desktop} {
      display: none;
    }
  }
`;
const ImageWrap = styled.div`
  position:relative;
  overflow:hidden;
  width: 100%;

  &:after{
    content:'';
    display:none;
    padding-bottom: 96%;
    @media ${breakpoints.desktop} {
      display:block;
    }
  }
  .react-reveal {
    position: relative;
    @media ${breakpoints.desktop} {
      position: absolute;
      width:100%;
      height:100%;
    }
  }
  .gatsby-image-wrapper {
    position: relative;
    @media ${breakpoints.desktop} {
      position: absolute;
      top:0;
    }
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const MetadataWrap = styled.div`
  position:relative;
  width: 0rem;

  p {
    position: absolute;
    z-index: 1;
    top: 0;
    left: var(--grid-padding);
    width: 0.8em;
    height: 100%;
    color: var(--brand-blue);
    display: none;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 -0.2rem;
    font-weight: 300;

    @media ${breakpoints.tabletSm} {
      display: flex;
      font-size: 1.0rem;
      left: calc(var(--grid-padding) / 2);
      margin-left: calc((var(--grid-padding) / 5.5) * -1);
    }
    @media ${breakpoints.tablet} {
      display: flex;
      font-size: 1.6rem;
      left: calc(var(--grid-padding) / 2);
      margin-left: 0rem;
    }
    @media ${breakpoints.desktop} {
      display: flex;
      font-size: 1.6rem;
      left: calc(var(--grid-padding) / 2);
      margin-left: calc((var(--grid-padding) / 5.5) * -1);
    }

    span {
      display: inline-block;
      transform: rotate(-90deg);
      white-space: nowrap;
    }

    strong {
      font-weight: 500;
    }
  }
  @media ${breakpoints.tabletSm} {
    width: var(--grid-padding);
  }
  @media ${breakpoints.tablet} {
    width: calc(var(--grid-padding) * 1.5);
  }
  @media ${breakpoints.desktop} {
    width: var(--grid-padding);
  }
`;

const SectionImage = styled.div`
  position: relative;
  display:flex;
  overflow: hidden;
  width: 100%;
  overflow: hidden;
  padding-right: 0;

  @media ${breakpoints.tabletSm} {
    width: 50%;
    padding-right: 0;
  }

  @media ${breakpoints.desktop} {
    padding-right: calc(var(--grid-padding) / 6);
  }

  @keyframes imageReveal {
    0% {
      opacity: 0;
      transform: scale3d(1.05, 1.05, 1);
    }

    100% {
      transform: none;
      opacity: 1;
    }
  }
`;

const Section = styled.section`
  background-color: #FFF;
  color:var(--brand-blue);
  padding: 0 var(--grid-padding) 7.2rem;

  &:nth-child(2) {
    flex-flow:row-reverse;

    ${SectionText} {
      
      @media ${breakpoints.tabletSm} {
        padding: 4rem calc(var(--grid-padding));
      }

      @media ${breakpoints.tablet} {
        padding: 4rem calc(var(--grid-padding)* 1.5);
      }

      @media ${breakpoints.desktop} {
        padding: 4rem calc(var(--grid-padding) * 1.8) 4rem calc(var(--grid-padding));
      }
    }

    ${SectionImage} {
      flex-flow:row-reverse;
      padding-left: 0;
      padding-right: 0;

      @media ${breakpoints.tabletSm} {
        padding-left: 0;
      }
      
      @media ${breakpoints.desktop} {
        padding-left: calc(var(--grid-padding) / 6);
      }

      p {
        @media ${breakpoints.tabletSm} {
          left: auto;
          right:calc(var(--grid-padding) / 2);
          margin-right: calc((var(--grid-padding) / 5.5) * -1);
          margin-left:0;
        }
        @media ${breakpoints.tablet} {
          left: auto;
          right:calc(var(--grid-padding) / 2);
          margin-right: 0;
          margin-left:0;
        }
        @media ${breakpoints.desktop} {
          left: auto;
          right:calc(var(--grid-padding) / 2);
          margin-right: calc((var(--grid-padding) / 5.5) * -1);
          margin-left:0;
        }
        span {
          @media ${breakpoints.tabletSm} {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  
  @media ${breakpoints.tabletSm} {
    display:flex;
    align-items:center;
    padding: 0 0 8rem;
  }

  @media ${breakpoints.tablet} {
    padding: 0 0 12rem;
  }
  
  &:last-child {
    padding-bottom: 0;
  }
`;

const Body = styled.div`
  width: 100%;
  color: #000000;
  font-size: 1.6rem;

  p{
    @media ${breakpoints.tabletSm} {
      margin-bottom: 1.5rem;
    }
  }

  @media ${breakpoints.tablet} {
    font-size: 1.9rem;
  }
`;

const CTAWrapper = styled.div`
  width: 100%;
  margin-top: 0.8rem;
  text-align: left;

  @media ${breakpoints.tabletSm} {
    margin-top: 2.5rem;
  }

  @media ${breakpoints.tablet} {
    margin-top: 4.2rem;
  }

  @media ${breakpoints.desktop} {
    margin-top: 4.0rem;
  }

  a {
    font-size: 1.2rem;
    line-height: 3.5rem;
    padding: 0 2.5rem;

    @media ${breakpoints.tabletSm} {
      font-size: 1.2rem;
      line-height: 3.5rem;
      padding: 0 2.5rem;
    }
  
    @media ${breakpoints.tablet} {
      line-height: 4.5rem;
      padding: 0 3.0rem;
    }

    @media ${breakpoints.desktopSm} {
      line-height: 5rem;
      padding: 0 5.5rem;
    }
  }
`;

export default function ZFlow({ sections }) {
  return (
    <Container>
      {sections.map((section, i) => (
        <Section key={i}>
          <SectionImage>
            <MetadataWrap>
              <Metadata as="p">{parse(section.caption)}</Metadata>
            </MetadataWrap>
            <ImageWrap>
              <Fade>
                <GatsbyImage image={getImage(section.image.imageFile)} alt={section.image.alt} />
              </Fade>
            </ImageWrap>
          </SectionImage>
          <SectionText>
            <SectionHeadingWrapper>
              <SectionHeading as="p" dangerouslySetInnerHTML={{ __html: section.heading }}></SectionHeading>
            </SectionHeadingWrapper>
            <Body dangerouslySetInnerHTML={{ __html: section.body }}></Body>
            {section.cta_text && (
            <CTAWrapper>
              <CTA className="cta-compact" data-theme="outline-black" href={section.cta_link}>
                {section.cta_text}
              </CTA>
            </CTAWrapper>
            )}
          </SectionText>
        </Section>
      ))}
    </Container>
  );
}
