import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import parse from "html-react-parser";
import breakpoints from "../../utils/breakpoints";

import { H2, Metadata } from "../typography";

const Container = styled.div`
  background-color: var(--brand-blue);
  color: #FFF;
  padding: 7rem var(--grid-padding) 8rem;

  @media ${breakpoints.tabletSm} {
    padding: 20rem var(--grid-padding);
  }

  @media ${breakpoints.tablet} {
    padding: 16rem var(--grid-padding);
  }

  @media ${breakpoints.desktop} {
    padding: 16rem var(--grid-padding);
  }
`;

const Date = styled.div`
  display: block;
  max-width: 70rem;
  margin: 0 auto;

  @media ${breakpoints.tablet} {
    text-align: center;
  }

  span {
    @media ${breakpoints.desktopSm} {
      font-weight: 400;
    }
  }
`;

const Heading = styled(H2)`
  margin: 0 auto 2.5rem;
  max-width: 70rem;
  color: #FBF4D0;
  font-size: 4rem;
  

  @media ${breakpoints.tabletSm} {
    margin: 0 auto 3.5rem;
    font-size: 5.4rem;
    text-align: center;
  }

  @media ${breakpoints.tablet} {
    margin: 0 auto 3.9rem;
    text-align: center;
    max-width: 15em;
  }

  @media ${breakpoints.desktop} {
    margin: 0 auto 3.5rem;
  }
`;

const Body = styled.div`
  max-width: 70rem;
  margin: 0 auto;
  color: #FFF;
  font-size: 1.6rem;
  p{
    margin-bottom:2.2rem;
  }

  @media ${breakpoints.tabletSm} {
    max-width: 62rem;
    font-size: 1.6rem;
  }

  @media ${breakpoints.tablet} {
    max-width: 64.4rem;
    font-size: 1.9rem;
    p{
      margin-bottom:2.5rem;
    }
  }

  @media ${breakpoints.desktop} {
    font-size: 1.9rem;
  }

  > :last-child {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    color: #3a797e;
    font-weight: 500;
  }
`;

export default function OpenLetter({ date, heading, body }) {
  return (
    <Container>
      {date && (
        <Date>
          <Metadata>{date}</Metadata>
        </Date>
      )}
      <Fade bottom distance="1.5rem" duration={800}>
        <Heading>{heading}</Heading>
      </Fade>
      <Body>{parse(body)}</Body>
    </Container>
  );
}
