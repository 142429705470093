import React, { useState } from "react";
import { withPrefix } from "gatsby";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { H1, p, Metadata } from "../typography";
import CTA from "../cta";
import breakpoints from "../../utils/breakpoints";

// import Swiper core and required modules
import SwiperCore, { Autoplay,EffectFade,Navigation,Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Autoplay,EffectFade,Navigation,Pagination]);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  overflow: hidden;

  @media ${breakpoints.tablet} {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    --swiper-theme-color: #FFF;
    --swiper-navigation-size: 2.4rem;
    .swiper-slide {
      opacity: 0!important;
    }
    .swiper-slide-active {
      opacity: 1!important;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 32vh;
      &:after {
        opacity:0;
      }
      &:before {
        content:'';
        position: absolute;
        display: block;
        width: 1.7rem;
        height: 1.7rem;
        border-right: 3px solid #FFF;
        border-bottom: 3px solid #FFF;
        top: 50%;
        left: 75%;
        transform: translate(-50%, -50%) rotate(135deg);
        transition: all 300ms ease-in-out 0ms;
      }

      @media ${breakpoints.mobile} {
        top: 43vh;
      }
      @media ${breakpoints.tablet} {
        top: 50%;
      }
    }
    .swiper-button-prev {
      left: var(--grid-padding);
    }
    .swiper-button-next {
      right: var(--grid-padding);
      &:before {
        left: 25%; 
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
`;

//vertical slides
const SlideVertical = styled.div`
  position: relative;
  display:block;
  width: 100%;
  height: auto;

  @media ${breakpoints.tablet} {
    display:flex;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
`;

const SlideImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 61vh;

  @media ${breakpoints.tablet} {
    width: 50%;
    height: 100%;
  }

  p {
    color: var(--brand-blue);
    position: absolute;
    left:var(--grid-padding);
    bottom:var(--grid-padding);
    font-weight: 400;
    font-size: 1.5rem;
    margin:0;
    z-index:100;
  }
  picture {
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
  & > div:nth-child(1) {
    height: 100%;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`;


const SlideContent = styled.div`
  width: 100%;
  height: auto;
  background-color: #224466;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0em var(--grid-padding);

  @media ${breakpoints.tablet} {
    width: 50%;
    height: 100%;
  }
  div[data-placeholder-image] {
    background: transparent !important;
  }
`;

const SlideLogo = styled(GatsbyImage)`
  width: auto;
  height: 1.5rem;
  margin-bottom: 1.5rem;
  img {
    object-fit: contain !important;
    object-position: left !important;
  }
  @media ${breakpoints.tabletSm} {
    height: 1.5rem;
    margin-bottom: 1.5rem;
  }

  @media ${breakpoints.tablet} {
    height: 2.0rem;
    margin-bottom: 2rem;
  }

  @media ${breakpoints.desktopSm} {
    height: 2.5rem;
    margin-bottom: 2rem;
  }
`;

const Body = styled.div`
  padding: 6rem 0 6.4rem;
  color: #FFF;
  h1 {
    font-size: 3.2rem;
    @media ${breakpoints.tabletSm} {
      font-size: 3.8rem;
    }
  
    @media ${breakpoints.tablet} {
      font-size: 4.0rem;
    }

    @media ${breakpoints.desktopSm} {
      font-size: 5.4rem;
    }
  }
  p {
    margin-top: 2rem;
    font-weight: 300;
    margin-bottom: 0;

    @media ${breakpoints.tabletSm} {
      margin-top: 2rem;
      margin-bottom: 0rem;
    }
    @media ${breakpoints.tablet} {
      margin-top: 3rem;
      margin-bottom: 1rem;
    }
  }
  @media ${breakpoints.tabletSm} {
    padding: 7rem 0 7rem;
  }
  @media ${breakpoints.tablet} {
    padding: 2em var(--grid-padding) 0 0;
  }
`;

const CTAWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;

  @media ${breakpoints.tabletSm} {
    margin-top: 2.5rem;
  }

  @media ${breakpoints.tablet} {
    margin-top: 3.0rem;
  }

  @media ${breakpoints.desktop} {
    margin-top: 7.5rem;
  }

  a {
    font-size: 1.4rem;
    line-height: 3.5rem;
    padding: 0 2.5rem;

    @media ${breakpoints.tabletSm} {
      font-size: 1.4rem;
      line-height: 3.5rem;
      padding: 0 2.5rem;
    }
  
    @media ${breakpoints.tablet} {
      line-height: 4.5rem;
      padding: 0 3.0rem;
    }

    @media ${breakpoints.desktopSm} {
      line-height: 5rem;
      padding: 0 5.5rem;
    }
  }
`;

//horizontal slides
const SlideHorizontal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 72rem;
  overflow: hidden;

  @media ${breakpoints.tabletSm} {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
`;
const Foreground = styled.div`
  position: relative;
  z-index: 2;
  margin-top: auto;
  color: #fff;
  padding: 0 var(--grid-padding) 2.8rem;

  @media ${breakpoints.tabletSm} {
    padding-bottom: 3.8rem;
  }
  @media ${breakpoints.tablet} {
    padding-bottom: 7.4rem;
  }
  @media ${breakpoints.desktop} {
    padding-bottom: calc(var(--grid-padding) / 1.5);
  }

  .react-reveal {
    opacity: 0;
  }

  .react-reveal:last-child {
    p {
      margin:0;
    }
    @media ${breakpoints.desktopSm} {
      position: absolute;
      bottom: calc(var(--grid-padding) / 1.3);
      right: var(--grid-padding);
    }
  }

  h1 {
    max-width: 20em;
    line-height:1em;

    span {
      display: inline;

      @media ${breakpoints.desktopSm} {
        display: block;
      }
    }

    @media ${breakpoints.tabletSm} {
      line-height: 1.0909090909;
      margin-bottom:2.8rem;
    }
  
    @media ${breakpoints.tablet} {
      line-height: 1.0740740741;
      margin-bottom:2.5rem;
    }
    @media ${breakpoints.desktop} {
      margin-bottom:0;
    }
  }

  p {
    display:none;
    margin-top: 1.5rem;
    font-weight: 400;
    margin-bottom: 0;

    @media ${breakpoints.tabletSm} {
      display:block;
      margin: 0 0 1rem 0;
    }

    @media ${breakpoints.desktopSm} {
      margin: 0 0 1rem 0;
    }

    strong {
      font-weight: 500;
    }
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .react-reveal {
    opacity: 0;
  }

  > span {
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background: linear-gradient(transparent, #000);
  }
`;

const BackgroundImage = styled.picture`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
  & > div {
    height: 100%;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`;

export default function Slider({
  slides,
  onchangeTheme
}) {
  let urlParams = null;
  if(typeof window !== "undefined") {
    urlParams = new URLSearchParams(window.location.search).get('slide');
    //vhfix
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  const [initial] = useState(
    urlParams === null ? 0 : slides.findIndex(item => item.slug === urlParams)
  );
  const onCtaClick = (url, target) => {
    // GTM event
    if (typeof window !== "undefined" && window.gtag) {
      // console.log("Analytics | CTA Opened: ", url);
      window.gtag('event', 'clicked', {
          'event_category': "cta",
          'event_label': `${url}`,
          'value': 1,
      });
    };
    window.open(url, target)
  };
  return (
    <Container>
      <Swiper
        initialSlide={initial}
        centeredSlides={true}
        slidesPerView={1}
        allowTouchMove={false}
        pagination={false}
        speed={300}
        effect={'fade'}
        autoHeight={true}
        autoplay={
          (slides.length > 1 ? 
            {
              "delay": 10000,
              "disableOnInteraction": false
            }
          : false)
        }
        loop={true}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        onSlideChange={(e) => {
          let theme = slides[e.realIndex].theme.value;
          onchangeTheme(theme);
        }}
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={i}>
            {slide.layout.value === "horizontal" ? 
              //horizontal layout
              <SlideHorizontal>
                <Foreground>
                  <Fade bottom distance="1.5rem" delay={1500}>
                    <H1>{parse(slide.heading)}</H1>
                    <Metadata as="p">{parse(slide.imageMeta)}</Metadata>
                  </Fade>
                </Foreground>
                <Background>
                  <Fade delay={500} duration={2000}>
                    <span></span>
                  </Fade>
                <BackgroundImage>
                    {/* <source
                      srcSet={`${withPrefix(slide.imageMobile)} 708w, ${withPrefix(
                        slide.imageMobile2x
                      )} 2x`}
                      media="(max-width: 1023px)"
                    />
                    <source
                      srcSet={`${withPrefix(slide.imageUrl)} 1440w, ${withPrefix(
                        slide.imageUrl2x
                      )} 2x`}
                      media="(min-width: 1024px)"
                    /> */}
                    <Fade>
                      {/* <img src={withPrefix(slide.imageMobile)} alt={slide.imageAlt} /> */}
                      <GatsbyImage loading="eager" image={getImage(slide.asset.imageFile)} alt={slide.asset.alt} />
                    </Fade>
                  </BackgroundImage>
                </Background>
              </SlideHorizontal>
            :
              //vertical layout
              <SlideVertical>
                <SlideImageWrapper>
                  {/* <SlideImage> */}
                    {/* <source
                      srcSet={`${withPrefix(slide.imageMobile)} 708w, ${withPrefix(
                        slide.imageMobile2x
                      )} 2x`}
                      media="(max-width: 1023px)"
                    />
                    <source
                      srcSet={`${withPrefix(slide.imageUrl)} 1440w, ${withPrefix(
                        slide.imageUrl2x
                      )} 2x`}
                      media="(min-width: 1024px)"
                    />
                      */}
                    <Fade>
                      {/* <img src={withPrefix(slide.imageMobile)} alt={slide.imageAlt} /> */}
                      <GatsbyImage placeholder="none" loading="eager" image={getImage(slide.asset.imageFile)} alt={''} />
                    </Fade>
                  {/* </SlideImage> */}
                  {slide.imageMeta &&
                  <Fade bottom distance="1.5rem" delay={1500}>
                    <Metadata as="p">{parse(slide.imageMeta)}</Metadata>
                  </Fade>
                  }
                </SlideImageWrapper>
                <SlideContent>
                  <Body>
                    <Fade bottom distance="1.5rem" delay={1500}>
                      {slide.logo ? <SlideLogo loading="eager" image={getImage(slide.logo.imageFile)} alt={slide.logo.alt} /> : "" }
                      {slide.heading ? <H1>{parse(slide.heading)}</H1> : "" }
                      {slide.copy ? <p>{parse(slide.copy)}</p> : "" }
                      {slide.cta_type && slide.cta_type?.value ? 
                        <CTAWrapper onClick={() => onCtaClick(slide.cta_type?.value === 'file' ? slide.cta_file?.imageFile?.publicURL : slide.cta, '_blank')}>
                          <CTA data-theme="outline" href="">
                            {slide.cta_text}
                          </CTA>
                        </CTAWrapper>
                      : "" }
                    </Fade>
                  </Body>
                </SlideContent>
              </SlideVertical>
            }
          </SwiperSlide>
        ))}
        {slides.length > 1 &&
        <Fade bottom distance="1.5rem" delay={1500}>
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </Fade>
        }
      </Swiper>
    </Container>
  );
}
